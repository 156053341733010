import React from "react";

const KanjiText = (props) => {
  return (
    <div className="p-2 mb-4 text-5xl font-bold text-transparent mb:mb-0 md:text-7xl lg:text-8xl bg-gradient-to-b from-chitored-50 font-kanji to-chitored-50/50 max-h-max bg-clip-text">
      {props.children}
    </div>
  );
};

export default KanjiText;
