import { Fragment } from "react";
import PageContentWrapper from "../Components/Layout/PageContentWrapper";
import PageTitle from "../Components/Layout/PageTitle";
import SubTitle from "../Components/Layout/SubTitle";

const TimeTable = () => {
  return (
    <Fragment>
      <PageTitle>Treningstider</PageTitle>
      <PageContentWrapper>
        <section className="w-2/3 mx-auto max-w-max">
          <SubTitle>Barn og ungdom</SubTitle>
          <div className="flex flex-col w-3/4 mx-auto mt-1">
            <div className="flex justify-center space-x-4">
              <p>Torsdag</p>
              <p className="whitespace-nowrap">18-19</p>
            </div>
          </div>
        </section>

        <section className="w-2/3 mx-auto max-w-max">
          <SubTitle>Voksne og ungdommer</SubTitle>
          <div className="flex flex-col mx-auto mt-1 ">
            <div className="flex justify-center space-x-4">
              <p>Mandag</p>
              <p>19:30-21</p>
            </div>
            <div className="flex justify-center space-x-4">
              <p>Onsdag</p>
              <p>19:30-21</p>
            </div>
            <div className="flex justify-center space-x-4">
              <p>Torsdag</p>
              <p>19:30-21</p>
            </div>
          </div>
        </section>
      </PageContentWrapper>
    </Fragment>
  );
};

export default TimeTable;
