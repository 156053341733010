import React, { Fragment } from "react";
import ImageCard from "../Components/Layout/ImageCard";
import KanjiText from "../Components/Layout/KanjiText";
import PageContentWrapper from "../Components/Layout/PageContentWrapper";
import PageTitle from "../Components/Layout/PageTitle";
import SubTitle from "../Components/Layout/SubTitle";
import OsenseiPhoto from "../Resources/images/tsuyoshi-chitose-full.jpg";
import FunakoshiPhoto from "../Resources/images/funakoshi.jpg";

const ChitoRyu = () => {
  return (
    <Fragment>
      <PageTitle>Chito Ryu</PageTitle>
      <PageContentWrapper>
        <section className="flex flex-col md:flex-row md:space-x-10">
          <article className="grid space-y-2 md:w-1/3 place-content-center">
            <KanjiText>
              <p>千</p>
              <p>唐</p>
              <p>流</p>
            </KanjiText>
          </article>
          <article className="">
            <SubTitle>Hva er Chito Ryu?</SubTitle>
            <div className="flex flex-col space-y-2">
              <p className="text-justify">
                {`Chito Ryu er en av mange retninger innen karate. Ettersom kunnskap har gått fra i arv fra mester til elev, gjennom generasjoner, har karate utviklet seg og det har oppstått mange forskjellige slike retninger. Disse retningene kalles stilarter, eller "ryu". Stilarten Chito Ryu ble stiftet av Okinawa-fødte`}
                <a
                  href="https://en.wikipedia.org/wiki/Chit%C5%8D-ry%C5%AB"
                  rel="noreferrer"
                  target="_blank"
                  className="in-text-link"
                >
                  {" Tsyuoshi Chitose "}
                </a>
                {`(1898-1984), i 1946. Selv om Chitose bodde og
                utviklet sin stilart i Kumamoto, Japan, anses stilarten som mer
                tradisjonell enn andre moderne stilarter. Det er fordi Chito Ryu
                inneholder mange kast- og låseteknikker, i tillegg til det man
                normalt vil forbinde med karate. Stillingene og teknikkene i
                Chito Ryu er også tydelig inspirert av tradisjonell Tōde
                (唐手).`}
              </p>
              <p className="text-justify">{`Navnet er sammensatt av 千(chi: tusen), 唐(tō: Tang-dynastiet i Kina) og 流(ryū: skole), og kan oversettes som "tusen år gammel kinesisk skole"`}</p>
              <p></p>
              <p>Chito Ryu kjennetegnes av:</p>
              <ol className="text-left list-disc ">
                <li className="ml-5">
                  Fokus på しめ (shime) - en kontraksjon av muskulaturen i nedre
                  del av kroppen for styrke og stabilitet i stillignene{" "}
                </li>
                <li className="ml-5">
                  Fokus på 絞り (shibori) - en sammentrekning av muskulatur i
                  bestemte områder, som armene, for sterkere teknikker{" "}
                </li>
                <li className="ml-5">
                  Hyppig bruk av 体捌き (
                  <a
                    href="https://en.wikipedia.org/wiki/Tai_sabaki"
                    rel="noreferrer"
                    target="_blank"
                    className="in-text-link"
                  >
                    tai sabaki
                  </a>
                  ) - bevegelser som bryter angrepslinjen{" "}
                </li>
              </ol>
            </div>
          </article>
        </section>
        <section className="flex flex-col-reverse translate-y-4 md:flex-row md:space-x-10">
          <article className="flex flex-col space-y-2 text-justify md:w-2/3">
            <SubTitle>Karatens Opprinnelse</SubTitle>
            <p className="text-justify">
              Karate er kjent som en japansk kampsport, men kommer opprinnelig
              fra øygruppen Okinawa. Øygruppen utgjorde fra 1429 kongeriket
              Ryukyu, før det så ble annektert og underlagt Japan i 1879. Øyene
              var i flere hundre år et sentralt knutepunkt for handel i
              regionen. I denne kulturelle smeltedigelen vokste øygruppens egen
              kampkunst frem. Kampkunsten ble opprinnelig kalt 手(Te), som betyr
              hånd, eller 唐手(Tōde) som betyr kinesisk hånd. Navnet falt ikke i
              smak hos japanerne, og navnet ble endret til 空手(karate), som
              betyr åpen hånd i 1935, etter at kampskunsten ble popularisert på
              det japanske fastlandet. Te var ikke en standardisert kampkunst,
              men ble praktisert med variasjon fra utøver til utøver. Regionale
              likheter eksisterte dog. De tidlige stilartene er kalt Shuri-te,
              Naha-te, og Tomari-te, og de fikk sine navnen fra de tre byene
              hvor stilartene vokste frem. Disse tre hovedretningene dannet, i
              kombinasjon med hverandre, grunnlaget for de mange stilartene
              innen karate som vi kjenner i dag, både på Okinawa og det japanske
              fastlandet. Den mest sentrale personen for populariseringen av
              karate ut over Okinawa var
              <a
                href="https://en.wikipedia.org/wiki/Gichin_Funakoshi"
                rel="noreferrer"
                target="_blank"
                className="in-text-link"
              >
                {" Gichin Funakoshi"}
              </a>
              (1868–1957). Han kom fra Shuri i Okinawa. Som en anerkjent utøver
              av flere stilarter ble han invitert av det japanske ministeriet
              for utdanning til å holde en demonstrasjon av karate i 1922. In
              1924 etablerte han den første universitetskarateklubben i Japan,
              ved Keiouniversitetet. Innen 1932 hadde alle de store
              universitetene i Japan lignende karaklubber.
            </p>
          </article>
          <article className="grid w-1/4 mx-auto space-y-2 md:w-1/3 place-content-center">
            <div className="flex flex-col space-y-2 text-center whitespace-nowrap">
              <ImageCard>
                <img
                  src={FunakoshiPhoto}
                  alt="A portrait of Gichin Funakoshi"
                  className="rounded-lg"
                />
              </ImageCard>
              <p>Gichin Funakoshi</p>
            </div>
          </article>
        </section>

        <section className="flex flex-col space-x-0 space-y-8 translate-y-4 md:space-x-8 md:space-y-0 md:flex-row">
          <article className="grid w-1/3 mx-auto place-content-center">
            <div className="flex flex-col space-y-2 text-center whitespace-nowrap">
              <ImageCard>
                <img
                  src={OsenseiPhoto}
                  alt="A portrait of the fouder of Chito Ryu"
                  className="rounded-lg"
                />
              </ImageCard>
              <p>O-sensei Tsyuoshi Chitose</p>
            </div>
          </article>
          <article className="w-full md:w-2/3">
            <SubTitle side="left">Tsyuoshi Chitose og Chito Ryu</SubTitle>
            <p className="text-justify">
              <a
                href="https://en.wikipedia.org/wiki/Chit%C5%8D-ry%C5%AB"
                rel="noreferrer"
                target="_blank"
                className="in-text-link"
              >
                {"Tsyuoshi Chitose "}
              </a>
              (1898-1984) kom også fra Okinawa. Han lærte karate fra tidlig
              skolealder. I tyveårene flyttet han til det japanske fastlandet,
              hvor utdannet seg til lege. Han underviste også for Gichin
              Funakoshi i mange år. Etterhvert ønsket han å kombinere sine
              kunnskaper om fysiologi med karate, og begynte arbeidet med å lage
              en ny karatestilart. Målet var å tilpasse karatens teknikker slik
              at de ble mindre belastede for ledd og muskelfester. Chitoses
              Chito Ryu ble også sterkt påvirket av hans bakgrunn fra Shorin Ryu
              og Shorei Ryu, og hans stilart har mange likehetstrekk med disse
              tradisjonelle stilartene fra Okinawa. Chitose åpnet så sin egen
              skole, i 1946, i Kumamoto. I 1948 startet Chitose, sammen med
              Gichin Funakoshi(nå kjent som grunnleggeren av Shotokan), Kenwa
              Mabuni (grunnleggeren av Shito-ryu), Yasuhiro
              Konishi(grunnleggeren av Ryobukai) og Gogen Yamaguchi(daværende
              leder for Goju-kai), det første japanske karateforbundet, kalt Zen
              Nihon Kenpo Karate-do Fukyokai.
            </p>
          </article>
        </section>
        <section>
          <article></article>
          <article></article>
        </section>
      </PageContentWrapper>
    </Fragment>
  );
};

export default ChitoRyu;
