import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ImageCard from "../Components/Layout/ImageCard";
import PageContentWrapper from "../Components/Layout/PageContentWrapper";
import PageTitle from "../Components/Layout/PageTitle";
import SubTitle from "../Components/Layout/SubTitle";
import worldLogo from "../Resources/images/world-chito-ryu.png";
import COACH_LIST from "../Resources/coach-list";
import Coach from "../Components/Coach";

const About = () => {
  const listOfCoaches = COACH_LIST.map((coach) => (
    <Coach
      key={coach.name}
      name={coach.name}
      male={coach.male}
      title={coach.title}
      rank={coach.rank}
      description={coach.description}
    />
  ));

  return (
    <Fragment>
      <PageTitle>Bodø karateklubb</PageTitle>
      <PageContentWrapper>
        <section>
          <SubTitle>Hvem er vi?</SubTitle>
          <div className="flex flex-col space-y-2 text-justify">
            <p>
              Bodø Karateklubb ble dannet i november 1980 av sensei Rune
              Ingebrigtsen. Klubben praktiserte stilarten Budo Kan fra 1980 til
              1993. Klubben skiftet deretter stilart til{" "}
              <Link className="in-text-link" to="/chitoryu">
                Chito Ryu
              </Link>
              . Vår hovedtrener heter sensei Jan Petter Markussen. Styreleder er
              Lars Lundquist. Klubben har omtrent femti medlemmer, hvorav to
              tredjedeler er barn og ungdom.
            </p>
            <p>
              Vi er en plass der du kan utvikle deg både mentalt og fysisk. En
              plass der man skaper vennskap og samhold. En plass der det er sunt
              for kropp og sjel å trene. Her kan alle trene og utvikle seg
              uansett alder og kjønn. Med klare målsettinger og fokus på
              resultater ønsker vi å oppnå selvtillit og glede gjennom mestring.
            </p>
          </div>
        </section>
        <section>
          <SubTitle>Klubbens trenere</SubTitle>
          <h2 className="text-center font-xl">
            Vi har mange dyktige trenere i klubben. Her er en kort presentasjon
            av dem.
          </h2>
          <ol className="grid grid-cols-1 gap-8 my-6 md:grid-cols-2">
            {listOfCoaches}
          </ol>
        </section>
        <section className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-8">
          <div className="w-full md:w-2/3">
            <SubTitle side="left">Oraganisering og forbund</SubTitle>
            <div className="flex flex-col space-y-2">
              <p className="text-justify">
                Klubben er underlagt{" "}
                <a
                  href="https://canadianchitoryu.ca/world-chito-ryu-federation/"
                  rel="noreferrer"
                  target="_blank"
                  className="in-text-link"
                >
                  World Chito Ryu Karate-Do-Federation
                </a>
                . Dette forbundet ledes fra Canada. Klubben har et tett
                samarbeid med våre kanadiske venner. Trenere fra Canada har
                besøkt Bodø flere ganger for å holde treningssamlinger med
                voksne og barn.
              </p>
              <p>
                Klubben er også medlem av{" "}
                <a
                  href="https://kampsport.no/"
                  rel="noreferrer"
                  target="_blank"
                  className="in-text-link"
                >
                  Norges Kampsportforbund
                </a>
                .
              </p>
            </div>
          </div>
          <div className="grid w-2/3 mx-auto md:w-1/3 place-content-center">
            <ImageCard>
              <a
                href="https://canadianchitoryu.ca/world-chito-ryu-federation/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={worldLogo}
                  className="px-6 py-3"
                  alt="Logo for the World Chito Ryu Karate-Do-Federation "
                />
              </a>
            </ImageCard>
          </div>
        </section>
        <section>
          <div>
            <div className="w-1/2 mx-auto text-sm text-center text-gray-500">
              Karateka Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/kmg-design"
                title="kmg design"
                target="_blank"
                rel="noreferrer"
              >
                kmg design
              </a>{" "}
              from{" "}
              <a
                href="https://www.flaticon.com/"
                title="Flaticon"
                target="_blank"
                rel="noreferrer"
              >
                www.flaticon.com
              </a>
            </div>
          </div>
        </section>
      </PageContentWrapper>
    </Fragment>
  );
};

export default About;
