import React from "react";
import { Routes, Route } from "react-router-dom";
import GeneralLayout from "./Components/Layout/GeneralLayout";
import About from "./Pages/About";
import ChitoRyu from "./Pages/ChitoRyu";
import ContactPage from "./Pages/ContactPage";


import Home from "./Pages/Home";
import Prices from "./Pages/Prices";
import TimeTable from "./Pages/TimeTable";

function App() {
  return (
    <GeneralLayout>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/chitoryu" element={<ChitoRyu />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="timetable" element={<TimeTable />} />
      </Routes>
    </GeneralLayout>
  );
}

export default App;
