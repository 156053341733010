import React from "react";

const PageTitle = (props) => {
  return (
    <section className="flex flex-col justify-center text-center">
      <h1 className="text-3xl font-black uppercase lg:text-5xl text-neutral-700">
        {props.children}
      </h1>
    </section>
  );
};

export default PageTitle;
