import React from "react";
import maleIcon from "../Resources/images/karateka-male.png";
import femaleIcon from "../Resources/images/karateka-female.png";

const Coach = (props) => {
  return (
    <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
      <div className="w-1/5 mx-auto">
        <img
          src={props.male ? maleIcon : femaleIcon}
          alt="Karateka Icon"
          className="drop-shadow-lg"
        />
      </div>
      <div className="flex flex-col w-full text-center sm:w-4/5 sm:text-left">
        <h3 className="font-bold text-chitored-50">{props.name}</h3>
        {props.title && <p className="font-bold">{`Tittel: ${props.title}`}</p>}
        <p className="font-bold">{`Grad: ${props.rank}`}</p>
        <p className="text-justify">{props.description}</p>
      </div>
    </div>
  );
};

export default Coach;
