import React, { Fragment, useState } from "react";
//import { Link } from "react-router-dom";
import logo from "../Resources/images/chito-ryu-crest-medium.png";
import HamburgerIcon from "./HamburgerIcon";
import LINK_LIST from "../Resources/link-list.js";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const menuButtonHandler = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const modalBackgroundHandler = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const clickHandlerFunction = (event) => {
    const selectedLink = event.target.getAttribute("data-target");
    navigate(selectedLink, { replace: false });
    setModalIsOpen(false);
  };

  const navList = LINK_LIST.map((item) => (
    <li
      key={item.title}
      className={item.cssClasses}
      data-target={item.dataTarget}
      onClick={clickHandlerFunction}
    >
      {item.title}
    </li>
  ));

  const logoClickHandler = () => {
    navigate("/", { replace: false });
  };

  return (
    <Fragment>
      <div className="relative bg-center bg-cover bg-white/40 bg-blend-lighten bg-bw-dojo-narrow ">
        <div className="absolute lg:hidden right-3 top-3">
          <HamburgerIcon
            modalIsOpen={modalIsOpen}
            menuButtonHandler={menuButtonHandler}
            modalBackgroundHandler={modalBackgroundHandler}
            linkList={navList}
          />
        </div>
        <div
          className="flex justify-center mt-0 mb-4 cursor-pointer"
          onClick={logoClickHandler}
        >
          <img
            src={logo}
            className="m-10 h-36 lg:h-52 xl:h-56 2xl:h-64"
            alt="The Chito Ryu logo"
          />
        </div>
        <div className="absolute bottom-0 w-full h-4 bg-gradient-to-t from-neutral-50 to-white/0 " />
      </div>
      <ul className="justify-center hidden mt-2 mb-4 space-x-4 text-lg font-bold text-gray-400 uppercase lg:flex">
        {navList}
      </ul>
    </Fragment>
  );
};

export default Header;

//<div className="absolute w-full h-2 -bottom-1 bg-neutral-50 " />
