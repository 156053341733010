const Footer = () => {
  return (
    <div className="flex mt-8 justify-center pb-2 mx-2 mb-4 space-x-4 text-xs text-center text-gray-400 uppercase md:justify-center">
      <p>Bodø Karateklubb</p>
      <p>
        <span className="whitespace-nowrap">Langstranda 5,</span>
        <span className="whitespace-nowrap">8003 BODØ</span>
      </p>
      <p>
        <span className="whitespace-nowrap">Org nr</span>
        <span className="whitespace-nowrap">984 182 317</span>
      </p>
    </div>
  );
};

export default Footer;
