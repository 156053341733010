import React from "react";

const ImageCard = (props) => {
  return (
    <div className="grid border-0 rounded-lg shadow-2xl border-gray-500/00 place-content-center">
      {props.children}
    </div>
  );
};

export default ImageCard;
