import React, { Fragment } from "react";
import PageContentWrapper from "../Components/Layout/PageContentWrapper";
import PageTitle from "../Components/Layout/PageTitle";
import SubTitle from "../Components/Layout/SubTitle";
import facebookLogo from "../Resources/images/facebook-icon.png";

const ContactPage = () => {
  return (
    <Fragment>
      <PageTitle>Kontakt oss</PageTitle>
      <PageContentWrapper>
        <section className="mb-10">
          <SubTitle>Du kan ta kontakt med oss på følgende måter</SubTitle>
          <div className="flex flex-col w-3/4 mx-auto mt-6 ">
            <div className="flex flex-col items-center justify-center mx-auto duration-300 hover:scale-110 max-w-max">
              <a
                href="https://www.facebook.com/bodokarateklubb"
                rel="noreferrer"
                target="_blank"
                className=""
              >
                <img
                  src={facebookLogo}
                  alt="The Facebook Logo"
                  className="h-20 "
                />
              </a>
              <div className="text-3xl font-bold text-center uppercase text-facebookBlue-500">
                <a
                  href="https://www.facebook.com/bodokarateklubb"
                  rel="noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
              </div>
            </div>
            <div className="w-1/2 mx-auto mt-4 duration-300 hover:scale-110 ">
              <a
                href="mailto:post@bodo-karateklubb.no"
                className="flex flex-col items-center space-y-4 font-bold"
              >
                <p className="font-blackduration-300 text-7xl text-chitored-50 ">
                  @
                </p>
                <p className="uppercase whitespace-nowrap">e-post</p>
              </a>
            </div>
          </div>
        </section>
        <section className="w-full mx-auto md:w-3/4">
          <SubTitle>
            Du finner våre{" "}
            <span className="whitespace-nowrap">treningslokaler her</span>{" "}
          </SubTitle>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1553.6564867695936!2d14.337940405155603!3d67.27052441634636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x45df104bc249b3f3%3A0xda61a07f01f21154!2sLangstranda%205%2C%208003%20Bod%C3%B8!5e0!3m2!1sno!2sno!4v1646774944963!5m2!1sno!2sno"
            className="w-full my-4 border-4 border-solid shadow-2xl h-60 md:h-96"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </section>
        <section className="text-center">
          <SubTitle>Administrasjon</SubTitle>
          <p className="mb-6">
            Styreleder kan kontaktes på telefon{" "}
            <span className="whitespace-nowrap">916 13 368</span>{" "}
          </p>
        </section>
      </PageContentWrapper>
    </Fragment>
  );
};

export default ContactPage;
