import Footer from "../Footer";
import Header from "../Header";

const GeneralLayout = (props) => {
  return (
    <div className="w-full lg:border-2 lg:border-neutral-50/80 2xl:w-1/2 lg:w-3/4 lg:mx-auto lg:my-10 lg:rounded-xl lg:overflow-hidden bg-neutral-50">
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="z-10 flex flex-col flex-1 w-5/6 mx-auto mt-3">
          {props.children}
        </main>
        <div className="w-3/4 mx-auto lg:w-2/3">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default GeneralLayout;
