import React, { Fragment } from "react";
import PageContentWrapper from "../Components/Layout/PageContentWrapper";
import PageTitle from "../Components/Layout/PageTitle";
import SubTitle from "../Components/Layout/SubTitle";

const Prices = () => {
  return (
    <Fragment>
      <PageTitle>Priser</PageTitle>
      <PageContentWrapper>
        <section>
          <p className="text-center ">Priser pr første januar 2022.</p>
        </section>

        <section className="text-center">
          <SubTitle>Voksne</SubTitle>
          <p>3000 NOK</p>
        </section>
        <section className="text-center">
          <SubTitle>Barn</SubTitle>
          <p>2000 NOK</p>
        </section>
        <section>
          <p className="text-justify">
            Prisene er oppgitt pr skoleår. Nye medlemmer kan avtale gratis
            prøvetimer ved skoleårets start. Vi tar betaling gjennom Vipps.
            Snakk med din trener dersom du har spørsmål omkring
            deltakeravgiften.
          </p>
        </section>
      </PageContentWrapper>
    </Fragment>
  );
};

export default Prices;
