import React from "react";
import MenuModal from "./MenuModal";

const HamburgerIcon = (props) => {
  const burgerLine = `h-1 w-9 my-[3px] rounded-full bg-black transition ease transform duration-300 bg-neutral-50 drop-shadow-md`;
  return (
    <div>
      {props.modalIsOpen && (
        <MenuModal
          navItemList={props.linkList}
          backGroundClick={props.modalBackgroundHandler}
        />
      )}
      <button
        className="z-50 flex flex-col items-center justify-center w-12 h-12 group"
        onClick={props.menuButtonHandler}
      >
        <div
          className={`${burgerLine} ${
            props.modalIsOpen
              ? "rotate-45 translate-y-2 opacity-80 group-hover:opacity-100"
              : "opacity-80 group-hover:opacity-100"
          }`}
        />
        <div
          className={`${burgerLine} ${
            props.modalIsOpen
              ? "opacity-0"
              : "opacity-80 group-hover:opacity-100"
          }`}
        />
        <div
          className={`${burgerLine} ${
            props.modalIsOpen
              ? "-rotate-45 -translate-y-3 opacity-80 group-hover:opacity-100"
              : "opacity-80 group-hover:opacity-100"
          }`}
        />
      </button>
    </div>
  );
};

export default HamburgerIcon;
