const navLinkItems = [
  {
    title: "Forsiden",
    cssClasses: "nav-bar-button ",
    dataTarget: "/",
  },
  {
    title: "Om klubben",
    cssClasses: "nav-bar-button ",
    dataTarget: "about",
  },
  {
    title: "Vår karatestil",
    cssClasses: "nav-bar-button",
    dataTarget: "chitoryu",
  },

  {
    title: "Treningstider",
    cssClasses: "nav-bar-button",
    dataTarget: "timetable",
  },
  {
    title: "Priser",
    cssClasses: "nav-bar-button",
    dataTarget: "prices",
  },
  {
    title: "Kontakt oss",
    cssClasses: "nav-bar-button",
    dataTarget: "contact",
  },
];

export default navLinkItems;
