import { Fragment } from "react";
import KanjiText from "../Components/Layout/KanjiText";
import PageContentWrapper from "../Components/Layout/PageContentWrapper";
import PageTitle from "../Components/Layout/PageTitle";
import SubTitle from "../Components/Layout/SubTitle";
import SlideShow from "../Components/SlideShow/SlideShow";

import facebookLogo from "../Resources/images/facebook-icon.png";

const Home = () => {
  const currentDate = new Date();
  const startDate = new Date(2024, 6, 29);
  const endDate = new Date(2024, 8, 30, 23, 59, 59, 999);

  return (
    <Fragment>
      <PageTitle>Bodø Karateklubb</PageTitle>
      <PageContentWrapper>
        <section className="items-center ">
          <article className="flex flex-col space-y-2 text-lg text-justify">
            <SubTitle>Velkommen til nettsidene til Bodø Karateklubb</SubTitle>
            <p>
              Vi er Bodøs eneste karateklubb, og tilbyr variert trening i
              romslige lokaler på Langstranda. Vårt mål er å skape begeistring
              og inspirasjon gjennom trening, for alle som er interessert i en
              annerledes treningsform, karate.
            </p>
          </article>
        </section>
        {currentDate >= startDate && currentDate <= endDate && (
          <section>
            <article className="flex flex-col space-y-2 text-lg text-center">
              <SubTitle>
                <p className="text-3xl text-red-700">Opptak for nybegynnere!</p>
              </SubTitle>

              <p>
                Bodø Karateklubb tar i mot nybegynnere fra første klasse og opp,
                fra og med 29 august 2024.
              </p>

              <p className="max-w-max mx-auto">
                Les mer på{" "}
                <a
                  href="https://www.facebook.com/events/359720127199850/?ref=newsfeed&locale=nb_NO"
                  rel="noreferrer"
                  target="_blank"
                  className="inline-block font-bold duration-500 hover:text-chitored-50 text-facebookBlue-500"
                >
                  Facebook-siden vår
                </a>
                .
              </p>
            </article>
          </section>
        )}
        <section id="newsSection">
          <SlideShow />
        </section>
        <section className="flex flex-col-reverse md:flex-row md:space-x-10">
          <article className="flex flex-col space-y-2 text-justify md:w-2/3">
            <SubTitle>Hva er karate?</SubTitle>
            <p>
              Karate er en treningsform som passer for alle, både voksne og
              barn. Det gir allsidig trening, styrke, kondisjon og bevegelighet.
            </p>
            <p>
              I karate bruker man hender og ben til å levere slag mot vitale
              kroppspunkter på motstanderen. Kampsporten drives som
              konkurranseidrett eller mosjonstrening. Slagene markeres vanligvis
              kun lett, men former med mer eller mindre full kontakt praktiseres
              også. Som i mange andre kampsporter, er karate preget av rituelle
              regler og stor vektlegging på utøverens mentale innstilling, ikke
              bare fysisk dyktighet.
            </p>
            <p>
              Karate ble først utviklet på øya Okinawa som en videreføring av
              gamle kinesiske nærkampteknikker.
            </p>
          </article>
          <article className="grid space-y-2 md:w-1/3 place-content-center">
            <KanjiText>
              <p>空</p>
              <p>手</p>
              <p>道</p>
            </KanjiText>
          </article>
        </section>
        <section className="flex flex-col items-center space-x-0 space-y-6 md:space-y-0 md:flex-row md:space-x-10">
          <article className="w-1/4">
            <a
              href="https://www.facebook.com/bodokarateklubb"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={facebookLogo}
                alt="The Facebook Logo"
                className="object-cover duration-300 hover:scale-105"
              />
            </a>
          </article>
          <article className="w-full md:w-3/4">
            <SubTitle side="left">Er du interessert i karate?</SubTitle>
            <div className="flex flex-col space-y-2 text-justify">
              <p>
                Bodø Karteklubb er et unikt treningstilbud i Bodø som passer for
                alle aldre. Vi har egne treninger for både voksne og barn, og vi
                har medlemmer fra 8 til 62 år.
              </p>
              {"  "}
              <p>
                Vi tilbyr gratis prøvetimer ved starten av hvert skoleår. Ta
                kontakt med oss på{" "}
                <a
                  href="https://www.facebook.com/bodokarateklubb"
                  rel="noreferrer"
                  target="_blank"
                  className="inline-block font-bold duration-500 hover:text-chitored-50 text-facebookBlue-500"
                >
                  facebook
                </a>{" "}
                for en uforpliktende prat!{" "}
                <p>
                  På facebooksiden finner du også mer informasjon og nyheter fra
                  klubben.
                </p>
              </p>
            </div>
          </article>
        </section>
      </PageContentWrapper>
    </Fragment>
  );
};

export default Home;
