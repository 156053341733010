import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';



const images = [
    'img/bkk_01.jpeg',
    'img/bkk_02.jpeg',
    'img/bkk_03.jpeg',
    'img/bkk_04.jpeg',
    'img/bkk_05.jpeg',
    'img/bkk_06.jpeg',
    'img/bkk_07.jpeg',
    'img/bkk_08.jpeg',
    'img/bkk_09.jpeg',
    'img/bkk_10.jpeg',
    'img/bkk_11.jpeg',
    'img/bkk_12.jpeg',
    'img/bkk_13.jpeg',
    'img/bkk_14.jpeg',
    'img/bkk_15.jpeg',
    'img/bkk_16.jpeg',
    'img/bkk_17.jpeg',
    'img/bkk_18.jpeg',
    'img/bkk_19.jpeg',
    'img/bkk_20.jpeg',
    'img/bkk_21.jpeg',
    'img/bkk_22.jpeg',
    'img/bkk_23.jpeg',
    'img/bkk_24.jpeg',
    'img/bkk_25.jpeg',
    'img/bkk_26.jpeg',
    'img/bkk_27.jpeg',
    'img/bkk_28.jpeg',
    'img/bkk_29.jpeg',  
    'img/bkk_31.jpeg',
    'img/bkk_32.jpeg',
    'img/bkk_33.jpeg',
    'img/bkk_34.jpeg',
    'img/bkk_35.jpeg',
    'img/bkk_36.jpeg',
    'img/bkk_37.jpeg',
    'img/bkk_38.jpeg',
    'img/bkk_39.jpeg',
    'img/bkk_40.jpeg',
    'img/bkk_41.jpeg',
    'img/bkk_42.jpeg',
    'img/bkk_43.jpeg',
    'img/bkk_44.jpeg',
    'img/bkk_45.jpeg',
    'img/bkk_46.jpeg',
    'img/bkk_47.jpeg',
    'img/bkk_48.jpeg',
    'img/bkk_49.jpeg',
]

/*

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 550px;
}


*/
    
const slides = images.map(image => (<div key={Math.random()} className="">
<div className='bg-center bg-no-repeat bg-cover aspect-auto h-[300px] md:h-[600px]' style={{ 'backgroundImage': `url(${image})` }}>

</div>
</div>))




const SlideShow = () => {
    return (
        <div className="mx-auto w-[200px] md:w-[400px]">

        <Slide cssClass="sliderClass" arrows={true} autoplay={true} slidesToShow={1} duration={3000} slidesToScroll={1}>
            {slides}
      
    </Slide>
        </div>
      )
}

export default SlideShow