import React from "react";

const MenuModal = (props) => {
  const backGroundClickHandler = props.backGroundClick;

  return (
    <div>
      <div
        className="fixed top-0 left-0 z-30 w-full max-h-max bg-white/60"
        onClick={backGroundClickHandler}
      />
      <section className="fixed left-[15%] sm:left-[30%] z-40 w-[70%] sm:w-[40%] mx-auto border rounded-md shadow-2xl backdrop-blur-sm bg-neutral-50/60 top-16 max-h-max">
        <ul className="flex flex-col items-center my-4 space-y-4 text-2xl uppercase">
          {props.navItemList}
        </ul>
      </section>
    </div>
  );
};

export default MenuModal;
