import React from "react";

const SubTitle = (props) => {
  let tailwindClasses = "text-lg font-bold text-center uppercase";
  if (props.side === "left") {
    tailwindClasses = tailwindClasses + " md:text-left";
  }
  if (props.side === "right") {
    tailwindClasses = tailwindClasses + " md:text-right";
  }

  return <h2 className={tailwindClasses}>{props.children}</h2>;
};

export default SubTitle;
