const coachList = [
  {
    name: "Jan Petter Markussen",
    title: "Renshi",
    rank: "Sjette Dan",
    male: true,
    description:
      "Jan Petter har trent karate siden 1980. Han har gradering i to stilarter, Budo-kan og Chito ryu. I 2017 graderte Jan Petter til sjette dan og tittlen Renshi under sensei Shane Higashi i Canada. Jan Petter er utdannet Trener 1 gjennom NKF/NIF, og er hovedtrener i klubben.",
  },
  {
    name: "Lars Lundquist",
    title: "Shihan",
    rank: "Fjerde Dan",
    male: true,
    description:
      "Lars har trent karate siden 1996. Han har også trent judo og t'ai chi. I 2017 graderte han til fjerde dan og tittelen Shihan under sensei Shane Higashi. Lars er utdannet Trener 1 gjennom NKF/NIF, og trener voksne, ungdom og barn. Han er også dommer på nasjonalt nivå og dommeransvarlig for region Nord.",
  },
  {
    name: "Pål Espen Jensen",
    title: "Shidoin",
    rank: "Fjerde Dan",
    male: true,
    description:
      "Pål Espen har trent karate siden 1996. Han har også trent judo og brasillian jiujitsu og er tidligere håndballspiller. I 2017 graderte han til Shidoin og 4. dan under sensei Shane Higashi. Pål er trener for voksne.",
  },
  {
    name: "Rita Lyngmo",
    title: "Junshidon",
    rank: "Andre Dan",
    male: false,
    description:
      "Rita har en allsidig sportslig bakgrunn, blant annet langløp på ski. Hun trener karate med den samme seriøsiteten som hun vier til de andre aktivitetene sine. Fikk svart belte under sensei Jan Petter Markussen sommeren 2012. Rita trener barne- og ungdomspartiet, og er utdannet Trener 1 gjennom NKF/NIF.",
  },
  {
    name: "Gro Sunniva Lundquist",
    title: false,
    rank: "Andre Dan",
    male: false,
    description:
      "Gro trener karate og boksing. Hun graderte til andre dan under sensei Shane Higashi i 2018. Gro trener barne- og ungdomspartiet.",
  },
  {
    name: "Erlend Nilssen",
    title: "Junshidon",
    rank: "Andre Dan",
    male: true,
    description:
      "Erlend har erfaring fra Shotokan i tillegg til Chito-Ryu. Han er trener for barn og ungdom.",
  },
];

export default coachList;
